import React from 'react';
import '../styles/common.less';
import style from '../styles/check.module.less';
import ElementScrollWatcher from 'element-scroll-watcher';
export default class Check extends React.Component {
  constructor(props){
    super(props);
    this.addItems = this.addItems.bind(this);
    this.setActive = this.setActive.bind(this);
    this.setIvc = this.setIvc.bind(this);
    this.state = {
      items : [{
        value : 0,
        active : false
      },{
        value : 1,
        active : false
      },{
        value : 2,
        active : false
      },{
        value : 3,
        active : false
      },{
        value : 4,
        active : false
      }],
      ivc : null,
      counter : 4
    }
  }
  addItems(){
    const {state} = this;
    state.counter = state.counter +1;
    state.items = state.items.concat({value : state.counter, active: false });
    this.setState(state);
    console.log(state.items, state.counter)
    state.ivc.update('.observeCheck');
  }
  setActive(index, value){
    const {state} = this;
    state.items[index].active = value
    this.setState(state);
  }
  setIvc(ivc){
    const {state} = this;
    state.ivc = ivc;
    this.setState(state);
  }
  componentDidMount(){
    const ivc = new ElementScrollWatcher('.observeCheck', { 
      root : window,
      active : (element)=>{
        const index = element.dataset.index;
        this.setActive(index, true);
        element.classList.add('active');
      },
      activeDelay: 1000,
      deActive: (element)=>{
        const index = element.dataset.index;
        this.setActive(index, false);
        element.classList.remove('active');
      },
      activePercentY: 65,
      deActivePercentY: 115
    });
    ivc.init();
    this.setIvc(ivc);
  }
  componentWillUnmount(){
    const {state} = this;
    state.ivc.destroy();
  }
  render(){
    const items = this.state.items.map(n=>{
      return <div data-index={n.value} key={n.value} className={`observeCheck ${style.listItem} ${n.active ? style.active : ''}`}>{n.value}</div>
    })
    return (
      <div className={style.wrap} >
        <button onClick={this.addItems}>add</button>
        {items}
      </div>
    )
  }
}